@import './variables.scss';

.horizontal-box {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
}

.ant-input-affix-wrapper.ant-input-small,
.ant-select.ant-input-small,
.ant-input-small {
  height: 24px;
  padding: 2px 4px;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  .ant-input {
    height: 20px;
    font-size: 8px;
    line-height: 1;
  }

  .ant-select-selection-item {
    line-height: 2;
  }
  .ant-select-selector {
    height: 20px;
    font-size: 8px;
    line-height: 1;
    padding: 0 6px;

    input {
      height: 20px !important;
      font-size: 8px;
      line-height: 1;
    }
  }
}

#zmmtg-root {
  display: none;
}

pre {
  word-wrap: pre-wrap !important;
  * {
    width: 100%;
  }
}

// Positioning

.pos-relative {
  position: relative;
}

.pos-absolute {
  position: absolute;
}

.pos-fixed {
  position: fixed;
}

.pos-static {
  position: static;
}

// width and height classes: w-0, w-5, ... , w-100
$__step: 5;
$__max: 100;
@for $i from 1 through $__max {
  .w-#{$i} {
    width: percentage($i / 100) !important;
  }
  .h-#{$i} {
    height: percentage($i / 100) !important;
  }
  .min-w-#{$i} {
    min-width: percentage($i / 100) !important;
  }
  .min-h-#{$i} {
    min-height: percentage($i / 100) !important;
  }
  .max-w-#{$i} {
    max-width: percentage($i / 100) !important;
  }
  .max-h-#{$i} {
    max-height: percentage($i / 100) !important;
  }
  $i: $i + $__step;
}

.w-auto {
  width: auto;
}

.h-auto {
  height: auto;
}

.w-33 {
  width: 33.33%;
}

.h-33 {
  height: 33.33%;
}

.w-66 {
  width: 66.66%;
}

.h-66 {
  height: 66.66%;
}

// Flex helpers

.d-none {
  display: none;
}

.d-flex {
  display: flex;
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-row {
  flex-direction: row;
}

.flex-col {
  flex-direction: column;
}

.align-items-start {
  align-items: flex-start;
}

.align-items-center {
  align-items: center;
}

.align-items-end {
  align-items: flex-end;
}

.justify-content-start {
  justify-content: flex-start;
}

.justify-content-center {
  justify-content: center;
}

.justify-content-end {
  justify-content: flex-end;
}

.justify-content-between {
  justify-content: space-between;
}

.justify-content-around {
  justify-content: space-around;
}

.justify-content-evenly {
  justify-content: space-evenly;
}

$__step: 1;
$__max: 20;
@for $i from 1 through $__max {
  .fg-#{$i} {
    flex-grow: $i;
  }

  .fs-#{$i} {
    flex-shrink: $i;
  }

  $i: $i + $__step;
}

// margin annd padding

.no-margin {
  margin: 0;
}

$sides: (
  't': 'top',
  'r': 'right',
  'b': 'bottom',
  'l': 'left'
);
$properties: (
  'p': 'padding',
  'm': 'margin'
);

@each $sideAbbrev, $side in $sides {
  @each $propAbbrev, $prop in $properties {
    .#{$propAbbrev}-0 {
      #{$prop}: 0 !important;
    }
    .#{$propAbbrev}-1 {
      #{$prop}: $baseline / 4 !important;
    }
    .#{$propAbbrev}-2 {
      #{$prop}: $baseline / 2 !important;
    }
    .#{$propAbbrev}-3 {
      #{$prop}: $baseline !important;
    }
    .#{$propAbbrev}-4 {
      #{$prop}: $baseline * 2 !important;
    }
    .#{$propAbbrev}-5 {
      #{$prop}: $baseline * 3 !important;
    }

    .#{$propAbbrev}#{$sideAbbrev}-0 {
      #{$prop}-#{$side}: 0 !important;
    }
    .#{$propAbbrev}#{$sideAbbrev}-1 {
      #{$prop}-#{$side}: $baseline / 4 !important;
    }
    .#{$propAbbrev}#{$sideAbbrev}-2 {
      #{$prop}-#{$side}: $baseline / 2 !important;
    }
    .#{$propAbbrev}#{$sideAbbrev}-3 {
      #{$prop}-#{$side}: $baseline !important;
    }
    .#{$propAbbrev}#{$sideAbbrev}-4 {
      #{$prop}-#{$side}: $baseline * 2 !important;
    }
    .#{$propAbbrev}#{$sideAbbrev}-5 {
      #{$prop}-#{$side}: $baseline * 3 !important;
    }
  }
}

$axes: (
  'x': 'left' 'right',
  'y': 'top' 'bottom'
);
$properties: (
  'p': 'padding',
  'm': 'margin'
);

@each $axis, $directionList in $axes {
  @each $propAbbrev, $prop in $properties {
    .#{$propAbbrev}#{$axis}-0 {
      @each $direction in $directionList {
        #{$prop}-#{$direction}: 0 !important;
      }
    }
    .#{$propAbbrev}#{$axis}-1 {
      @each $direction in $directionList {
        #{$prop}-#{$direction}: $baseline / 4 !important;
      }
    }
    .#{$propAbbrev}#{$axis}-2 {
      @each $direction in $directionList {
        #{$prop}-#{$direction}: $baseline / 2 !important;
      }
    }
    .#{$propAbbrev}#{$axis}-3 {
      @each $direction in $directionList {
        #{$prop}-#{$direction}: $baseline !important;
      }
    }
    .#{$propAbbrev}#{$axis}-4 {
      @each $direction in $directionList {
        #{$prop}-#{$direction}: $baseline * 2 !important;
      }
    }
    .#{$propAbbrev}#{$axis}-5 {
      @each $direction in $directionList {
        #{$prop}-#{$direction}: $baseline * 3 !important;
      }
    }
  }
}
// Other stuff

.overflow-y-scroll {
  overflow-y: scroll;
}

.overflow-x-scroll {
  overflow-x: scroll;
}

.overflow-scroll {
  overflow: scroll;
}

.hover-bg-blue {
  transition: background-color 0.2s ease-in-out;
}

.hover-bg-blue:hover {
  background-color: #1890ff19;
}

.bg-white {
  background-color: #ffffff;
}

.bg-transparent {
  background-color: transparent;
}

.border-radius {
  border-radius: 4px;
}

.border-radius-50 {
  border-radius: 50%;
}

.border-gray {
  border: 1px solid $lightGray;
}

.border-lightgray {
  border: 1px solid $borderLight;
}

.border-mediumgray {
  border: 1px solid $borderMedium;
}

.border-red {
  border: 1px solid #ff4d50;
}

.color-gray {
  color: $lightGray;
}

.color-red {
  color: #ff4d50;
}

.color-green {
  color: $green-8 !important;
}

.color-white {
  color: #ffffff;
}

.color-muted {
  color: #bfbfbf;
}

.font-0 {
  font-size: 0.25rem;
}
.font-1 {
  font-size: 0.5rem;
}
.font-2 {
  font-size: 0.75rem;
}
.font-3 {
  font-size: 1rem;
}
.font-4 {
  font-size: 2rem;
}
.font-5 {
  font-size: 3rem;
}

.font-weight-700 {
  font-weight: 700;
}

.font-weight-600 {
  font-weight: 600;
}

.font-weight-500 {
  font-weight: 500;
}

.font-weight-400 {
  font-weight: 400;
}

.font-weight-300 {
  font-weight: 300;
}

.font-weight-200 {
  font-weight: 200;
}

.font-weight-100 {
  font-weight: 100;
}

.ws-normal {
  white-space: normal;
}

// Debug stuff

.debug-red {
  background-color: #ff000011;
}

.debug-green {
  background-color: #00ff0011;
}

.debug-blue {
  background-color: #0000ff11;
}

.debug-cyan {
  background-color: #00ffff11;
}

.debug-magenta {
  background-color: #ff00ff11;
}

.debug-yellow {
  background-color: #ffff0011;
}
