@import '../../../styles/util.scss';
@import '../../../styles/variables.scss';

input.ant-input {
  color: #444444;
}

.input-tag-container,
.input-date,
.input-select .ant-select-selector,
.input-number,
.input-checkbox {
  min-height: 42px;
  display: flex;
  align-items: center;
  height: 100%;
}

.cursor-default .ant-checkbox-input {
  cursor: default;
}

.ant-upload-row {}

.input-container:hover {

  .input-tag-container,
  .input-date,
  .input-number {
    border-color: #40a9ff;
    transition: border-color 0.2s;
  }
}

.input-tag-container {
  transition: border-color 0.2s;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-left: $baseline;
  padding-right: 0;
  margin-right: 0;
  border-top-left-radius: 0.125rem;
  border-bottom-left-radius: 0.125rem;
  align-self: stretch;
  background: $lightBackground;
  border: 1px solid $borderMedium;
  border-right: unset;

  .input-prefix {
    margin-right: 0;
  }
}

.payments {
  .fields-container * {
    font-size: 0.75rem !important
  }
}

.input-prefix {
  height: auto;
  border: unset;
  background-color: $steelGray;
}

.input-select {
  width: 100%;
  overflow-x: scroll;

  .ant-select-selector {
    border-left-width: 0 !important;
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
    flex-grow: 1;
  }
}

.input-number,
.input-date {
  transition: border-color 0.2s;
  flex-grow: 1;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-left-style: unset;
  margin-left: 0 !important;

  &.ant-picker-cused {
    box-shadow: unset;
  }

  &.ant-input-number-focused {
    outline: none;
    box-shadow: none;
  }
}

.input-container,
.status-container,
.input,
div.ant-input-number-input-wrap {
  transition: border-color 0.2s;

  &.save-success {
    border-color: green;
    border-width: 2px;
    border-style: solid;
    margin: 0;
    transition: border-color 0.2s;

    &:after {
      transition: all 0.2s;
      position: absolute;
      height: 100%;
      width: 24px;
      top: 0;
      right: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      color: green;
      content: '✓';
    }
  }

  &.save-error {
    border-color: red;
    border-width: 2px;
    border-style: solid;
    margin: 0;
    transition: border-color 0.2s;

    &:after {
      transition: all 0.2s;
      position: absolute;
      height: 100%;
      width: 24px;
      top: 0;
      right: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      color: red;
      content: '✕';
    }
  }
}

.no-field-padding {

  .input-container {
    padding-right: 0;
    padding: 0;
    margin: 0;
    margin-bottom: 0;
  }

}

.input-container {
  color: #444444;
  padding-right: $baseline;
  margin-bottom: $baseline;
  min-height: 42px;

  &.hr {
    min-height: $baseline;
    margin-bottom: 0;
    width: 100%;

    .hr-inner {
      height: 0;
      border-top: 1px solid $borderLight;
    }
  }
}

.fields-container {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  align-items: center;
}

.array-container {
  padding-left: $baseline;
  border-left: 2px solid $borderDark;

  .array-container-inner {
    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-start;
    align-items: flex-start;

    .array-item {
      padding: $baseline;
      margin-bottom: $baseline * 2;
      border: 1px solid $borderMedium;

      &.array-item-even {
        background-color: $evenColor;
      }

      &.array-item-odd {
        background-color: $oddColor;
      }

      .array-index {
        padding-bottom: $baseline;

        &>span {
          min-width: $baseline * 2;
          min-height: $baseline * 2;
          border: 1px solid $borderMedium;
          background-color: $lightBackground;
        }
      }
    }
  }
}