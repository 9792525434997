@import '../../../styles/util.scss';
@import '../../../styles/variables.scss';

#root #fp-id {
  .table-header {
    width: 100%;

    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    border: 1px solid $borderLight;
    border-bottom: unset;
    background-color: $lightBackground;

    .action-container {
      @extend .horizontal-box;
      min-height: 30px;

      .actions-start {
        @extend .horizontal-box;
        min-height: 30px;
        padding-left: 10px;
        width: 45%;

        .icon {
          padding-right: $baseline;
        }
        .search-input {
          max-width: 50%;
        }
      }
      .actions-center {
        min-height: 30px;
        width: 10%;
        padding: $baseline / 2;
      }
      .actions-end {
        @extend .horizontal-box;
        min-height: 30px;
        padding-right: $baseline;
        width: 45%;
        justify-content: flex-end;
      }
    }
    .form-container {
      overflow-y: scroll;
      max-height: 0;
      width: 100%;
      padding: 0;
      box-shadow: inset 0 2px 2px $borderLight, inset 0 -1px 2px $borderLight;
      background-color: $lightBackground;
      transition: max-height 0.1s ease-in-out;

      &.visible {
        max-height: 50vh;
        transition: max-height 0.1s ease-in-out;
      }

      .form-inner {
        height: auto;
        padding: $baseline * 2;
        padding-bottom: $baseline;
        h4 {
          padding-bottom: $baseline * 2;
          margin-bottom: 0;
        }

        .form-flex {
          @extend.horizontal-box;
          flex-wrap: wrap;
          margin-right: -($baseline * 2);
          .form-item {
            width: 33.33%;
            padding-right: $baseline * 2;

            .form-input {
              width: 100%;
            }

            .ant-form-item-label {
              min-width: 33.33%;
              text-align: left;
            }
          }
        }
      }
    }
  }
}
