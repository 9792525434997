@import '../../styles/util.scss';
@import '../../styles/variables.scss';

#fp-id {
  width: 100%;
  height: 100%;

  .ant-input-affix-wrapper .ant-input:not(:first-child) {
    padding-left: 0;
  }

  .ant-input-prefix {
    position: relative;
    top: 0;
    left: 0;
    transform: unset;
  }

  .id-routes {
    width: 100%;
    height: 100%;
    padding: $baseline;
  }
}
