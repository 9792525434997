/*@import stuff here*/
@import url('https://fonts.googleapis.com/css2?family=Work+Sans:wght@300;500;700&display=swap');

#dossiers .ant-radio-wrapper {
  /* margins break ant's tables*/
  margin-right: 0;
}

.ant-collapse-content-box {
  padding: 4px 16px 4px 40px !important /*fuckng Antd*/;
}

.ant-menu-horizontal > .ant-menu-item {
  border: none !important;
}
.ant-layout-content .ant-menu-horizontal > .ant-menu-item:hover,
.ant-layout-content .ant-menu-horizontal > .ant-menu-submenu:hover,
.ant-layout-content .ant-menu-horizontal > .ant-menu-item-active,
.ant-layout-content .ant-menu-horizontal > .ant-menu-submenu-active,
.ant-layout-content .ant-menu-horizontal > .ant-menu-item-open,
.ant-layout-content .ant-menu-horizontal > .ant-menu-submenu-open,
.ant-layout-content .ant-menu-horizontal > .ant-menu-item-selected,
.ant-layout-content .ant-menu-horizontal > .ant-menu-submenu-selected {
  color: #eef6ff;
  background-color: #228aff;
}

.ant-select.fullwidth-select .ant-select-selection-selected-value {
  width: 100%;
  padding-right: 12px;
}

body {
  font-family: 'Work Sans', sans-serif !important;
  background-color: #f0f2f5;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 300 !important;
}

h1 {
  font-size: 36px;
  & > span.ant-tag {
    font-size: 36px;
    line-height: 46px;
  }
}
h2 {
  font-size: 30px;
  & > span.ant-tag {
    font-size: 30px;
    line-height: 40px;
  }
}

h3 {
  font-size: 24px;
  & > span.ant-tag {
    font-size: 24px;
    line-height: 34px;
  }
}

h4 {
  font-size: 18px;
  & > span.ant-tag {
    font-size: 18px;
    line-height: 28px;
  }
}

#root {
  height: 100%;
}

div.app {
  height: 100%;
}
