@import '../../../styles/util.scss';
@import '../../../styles/variables.scss';

#irs {
  overflow-y: scroll;

  .transform-file {
    .ant-upload-list-item-info > span {
      display: flex;
      flex-flow: row nowrap;
      justify-content: flex-start;
      align-items: center;

      & > i {
        position: relative;
        top: 0;
      }

      .ant-upload-list-item-name {
        width: auto;
      }

      & > * {
        margin-right: 3px;
      }
    }
  }
}
