@import '../../../../styles/util.scss';
@import '../../../../styles/variables.scss';

#single-client {
  input.ant-input {
    color: #444444;
  }
  .input-prefix {
    border: unset;
    background-color: $steelGray;
  }
}
