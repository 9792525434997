$borderLight: #e8e8e8;
$borderMedium: #d9d9d9;
$borderDark: #b8b8b8;
$oddColor: #fcfdfd;
$evenColor: #f5f8fa;
$relativeColor: #ECF2FE;
$baseline: 12px;
$lightBackground: #ffffff;

$lightGray: #666666;
$steelGray: #e2e8ed;

$baseline: 12px;

$green-8: #237804;
