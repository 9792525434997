@import '../../../styles/util.scss';
@import '../../../styles/variables.scss';

#root #fp-id {
  .text-muted {
    color: #8f8f8f;
  }

  .ReactVirtualized__Table {
    .ReactVirtualized__Table__Grid {
      border: 1px solid $borderLight;
    }

    .ReactVirtualized__Table__headerColumn {
      height: 100%;
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      font-size: 10px;
      font-weight: 500;
      margin-right: 0;
      margin-left: 0;
      // margin-right: 0.5%;
      // margin-left: 0.5%;
      // &:first-child {
      //   margin-right: 1%;
      //   margin-left: 1%;
      // }
    }

    .ReactVirtualized__Table__rowColumn {
      position: relative;
      height: 100%;
      display: flex;
      flex-flow: row nowrap;
      justify-content: flex-start;
      align-items: center;
      font-size: 12px;
      font-weight: 400;
      overflow-wrap: break-word;
      white-space: normal;
      margin-right: 3px;
      margin-left: 3px;
      // margin-right: 0.5%;
      // margin-left: 0.5%;
      // &:first-child {
      //   margin-right: 1%;
      //   margin-left: 1%;
      // }
      &:not(.no-hover-effects):hover {
        background-color: #efefef;
      }
    }

    .ReactVirtualized__Table__headerRow {
      border: 1px solid $borderLight;
      border-bottom: unset;
      background-color: $oddColor;
    }

    .ReactVirtualized__Table__row {
      border-bottom: 1px solid $borderLight;
    }

    .odd-row {
      background-color: $oddColor;
    }
    .even-row {
      background-color: $evenColor;
    }

    .relative-row {
      background-color: $relativeColor;
      &:nth-child(2) {
        box-shadow: inset 0 2px 6px -6px $lightGray;
      }
      &:last-child {
        box-shadow: inset 0 -2px 6px -6px $lightGray;
      }

      &:nth-child(2):last-child {
        box-shadow: inset 0 -2px 6px -6px $lightGray,
          inset 0 2px 6px -6px $lightGray;
      }
    }

    .header-filter {
      padding-right: 10px;
      padding-left: 10px;
      height: 60%;
      flex-grow: 1;
      border-top: 1px solid $borderLight;

      &.no-padding {
        padding-right: 0;
        padding-left: 0;
      }
    }

    .inner-header {
      &:not(:last-child) {
        border-right: 1px solid $borderLight;
      }
      padding-right: 10px;
      padding-left: 10px;
      height: 40%;
      flex-grow: 1;

      .anticon.anticon-caret-down,
      .anticon.anticon-caret-up {
        padding-left: $baseline / 2;
      }

      svg {
        fill: #1890ff;
      }
      &.sort-inactive {
        svg {
          fill: #999999;
        }
      }
    }

    .inner-header,
    .inner-cell {
      max-width: 100%;
      white-space: normal;
      overflow-wrap: break-word;
      overflow: hidden;
    }

    .inner-cell {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
    }

    .row-link {
      height: 100%;
      font-size: 12px;
      font-weight: 400;
      text-transform: uppercase;
    }

    .button-padding {
    }

    .link-padding {
      padding: 0 7px;
    }

    .button-invisible {
      border-color: transparent;
      width: 32px;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
    }
  }
}
