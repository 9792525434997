@import '../../../styles/util.scss';
@import '../../../styles/variables.scss';

#create-relative {
  &.form-container {
    overflow-y: scroll;
    width: 100%;
    padding: 0;

    .form-inner {
      height: auto;
      h4 {
        padding-bottom: $baseline * 2;
        margin-bottom: 0;
      }

      .form-item {
        padding-right: $baseline * 2 !important;

        .form-input {
          width: 100%;
        }

        .ant-form-item-label {
          min-width: 33.33%;
          text-align: left;
        }
      }
    }
  }
}
