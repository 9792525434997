@import '../../../styles/util.scss';
@import '../../../styles/variables.scss';

#family-tree {
  .family-header {
    margin-bottom: $baseline;
    border-bottom: 1px solid $borderLight;
    padding: $baseline;
    padding-left: 0;    

    .row-link {
      padding-left: $baseline / 2;
      padding-right: $baseline / 2;
    }

    .icon-back {
      font-size: $baseline * 2;
      font-weight: 700;
    }
  }

  .header-icon {
    padding-right: $baseline / 2;
  }

  .header-title {
    font-weight: 700;
    padding-right: $baseline;
  }

  .sidebar {
    margin-right: $baseline;

    .card-body {
      .member-button {
        padding-left: $baseline;
        padding-right: $baseline;
        margin-bottom: $baseline;
        &.focused {
          color: #40a9ff;
          border-color: #40a9ff;
        }
      }
      .main-investor {
        padding-bottom: $baseline;
      }

      .family {
      }
    }
    .operations {
      .operation {
        padding: $baseline;
        padding-left: 0;
      }
    }
  }

  .card {
    border-radius: 4px;
    background-color: $lightBackground;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    box-shadow: 0 1px 4px $borderLight;
    overflow-y: scroll;

    .card-header {
      width: 100%;
      padding: $baseline;
      flex-shrink: 0;
      min-height: 3rem;
      border-bottom: 1px solid $borderLight;

      .card-header-heading {
        margin: 0;
      }
    }

    .card-body {
      width: 100%;
      padding: $baseline;
      overflow-y: scroll;
    }

    .card-footer {
      width: 100%;
      padding: $baseline;
      flex-shrink: 0;
      min-height: 3rem;
      border-top: 1px solid $borderLight;
    }
  }
}
